@import "./icons.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// RTL
// @import "custom/rtl/general-rtl";
// @import "custom/rtl/bootstrap-rtl";
// @import "custom/rtl/spacing-rtl";
// @import "custom/rtl/float-rtl";
// @import "custom/rtl/text-rtl";
// @import "custom/rtl/structure-rtl";
// @import "custom/rtl/plugins-rtl";
// @import "custom/rtl/components-rtl";
// @import "custom/rtl/pages-rtl";

body {
  * {
    outline: none !important;
  }
}

.bg-primary {
  background-color: #000066 !important;
}

/* close button **/
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width
    auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

.ReactModal__Overlay {
  z-index: 5000 !important;
}
.rangeslider__handle:focus {
  outline: none;
}
// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}
.search-label {
  float: right;
}

/* rating**/
.rating-container {
  background-color: transparent !important;
}

/* form editor **/
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding: 15px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}

.search-box .search-icon-search {
  font-size: 16px !important;
  position: absolute !important;
  left: 13px !important;
  top: 0 !important;
  line-height: 38px !important;
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 30px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #5156be !important;
}
.preview-thumbsnav {
  display: flex !important;
  list-style-type: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background: #5156be !important;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #5156be !important;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #5156be !important;
}

// Flatepicker
.flatpickr-months,
.flatpickr-weekdays,
.flatpickr-monthDropdown-months {
  background-color: #42a5f5 !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background-color: #42a5f5 !important;
}

.search-box .search-icon {
  font-size: 16px;
  position: absolute;
  left: 13px;
  top: 0;
  line-height: 38px !important;
}
.carousel-indicators button,.carousel-indicators li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-transition: opacity .6s ease;
  transition: opacity .6s ease;
  width: 30px!important;
  height: 3px!important;
}
.dashboard-slider.carousel-indicators li {
  width: 10px!important;
  height: 10px!important;
  border-radius: 50%!important;
}

.dashboard-slider.carousel-indicators{
  position: relative;
}
#reviewcarouselIndicators .carousel-indicators{
  margin-left: 0;
  float: left;
}
.bx{
  font-weight: 400;
}
.sweet-alert h2 {
  font-size: 22px !important;
  font-weight: 500 !important;
}
.dropdown-toggle::after,.dropstart .dropdown-toggle::before{
  display:none !important;
}
//react-table
.custom-header-css {
  thead {
    tr {
      th {
        div:last-child {
          min-height: 38px;
        }
      }
    }
    td:nth-last-child(2) {
      width: 150px !important;
    }
  }
}
.carousel-indicators [data-bs-target]{
  font-size: 0;
}